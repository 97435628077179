import { createApp } from 'vue'

import App from './App.vue'
import '@/assets/styles.scss'
import rollbar from '@/services/rollbar'
import { getMountingNode } from './utils/dom'

const app = createApp(App)

app.config.errorHandler = err => {
  rollbar.error(err)
}

const mountApp = () => app.mount(getMountingNode())

if (document.body) {
  mountApp()
} else {
  const observer = new MutationObserver(() => {
    mountApp()
    observer.disconnect()
  })

  observer.observe(document.body, { subtree: false, attributes: true })
}
