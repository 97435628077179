<template>
  <Modal v-if="showModal">
    <IFrameLoader
      :formUrl="formUrl"
      :outcome="outcome"
      :clickSource="clickSource"
      :fallbackUrl="fallbackUrl"
    />
  </Modal>
  <IFrameLoader
    v-if="!showModal && outcome && outcome.success"
    :formUrl="formUrl"
    :outcome="outcome"
    :clickSource="clickSource"
    :fallbackUrl="fallbackUrl"
  />
</template>

<script>
import Modal from '@/components/Modal'
import rollbar from '@/services/rollbar'
import { onMounted, ref, watch } from 'vue'
import { toggleBodyClass } from '@/utils/dom'
import { updateQueryParams } from '@/utils/url'
import IFrameLoader from '@/components/IframeLoader'
import { sanitizeData, emitContextData } from '@/utils/contextFields'

const expectedAttrs = [
  'data-coverage-fallback-url',
  'data-bankrate-form-trigger',
  'data-bankrate-form-url'
]

const hasValidAttributes = attributes => {
  const [, ...requiredAttrs] = expectedAttrs
  return requiredAttrs.every(
    attr => attributes[attr] && !!attributes[attr].value
  )
}

export default {
  name: 'App',

  components: {
    Modal,
    IFrameLoader
  },

  setup() {
    const formUrl = ref('')
    const outcome = ref(null)
    const clickSource = ref('')
    const fallbackUrl = ref('')
    const showModal = ref(false)
    const contextData = ref(null)

    let startTime

    /**
     * When loading an arbitrary url that doesn't use the coverage gate,
     * we will not wait for a message, instead we will force the iframe to just
     * load the provided URL.
     */
    const determineIframeStatus = () => {
      if (formUrl.value.includes('coverage-gate')) {
        return
      }

      setTimeout(() => {
        outcome.value = { success: true }
      }, 750)
    }

    const triggerHandle = (
      formUrlValue,
      clickSourceValue,
      fallbackUrlValue = '',
      inPage = false
    ) => {
      startTime = Date.now()
      formUrl.value = formUrlValue
      clickSource.value = clickSourceValue
      fallbackUrl.value = fallbackUrlValue

      showModal.value = !inPage

      if (inPage) {
        outcome.value = { success: true }
      }

      // TO DO: send to new relic
      // datadog.increment('elf_loaded', { click_source: clickSource.value })

      determineIframeStatus()
    }

    const getWidgetUrl = (category, context) => {
      const categories = [
        'myfi_va',
        'myfi_acd',
        'myfi_sleep',
        'myfi_heloc',
        'myfi_mortgages',
        'myfi_insurance',
        'myfi_credit_title',
        'myfi_credit_monitor',
        'better_apply',
        'better_preapproved',
        'better_prequalify',
        'better_post_re_ny'
      ]

      if (!categories.includes(category)) {
        throw new Error(
          `Invalid category, expected values are: ${categories.join(', ')}`
        )
      }

      let url

      if (category.startsWith('myfi_')) {
        url = `https://www.myfinance.com/c/?mf_referrer=${window.location.href}&category=${category}`
      }

      if (category.startsWith('better_')) {
        url = `https://mortgage.bankrate.com/partner-redirect/${category}`
      }

      return context ? updateQueryParams(url, sanitizeData(context)) : url
    }

    const getInPageIFrameUrl = (iFrameUrl, context) => {
      return context
        ? updateQueryParams(iFrameUrl, sanitizeData(context))
        : iFrameUrl
    }

    // Sends timing metric when app loads
    watch(
      outcome,
      newOutcome => {
        if (newOutcome && !!newOutcome.success) {
          if (startTime > 0) {
            // TO DO: send metric to new relic
            // datadog.timing('elf_form_load_timing', Date.now() - startTime)
          } else {
            // TO DO: send to new relic
            // datadog.increment('elf_form_load_timing_failure')
            rollbar.warning('Initial timing metric not set', {
              formUrl: formUrl.value,
              clickSource: clickSource.value,
              fallbackUrl: fallbackUrl.value
            })
          }

          emitContextData(contextData.value)
        }
      },
      { deep: true }
    )

    // Adds/removes class to page's body to prevent scrolling
    watch(showModal, toggleBodyClass)

    onMounted(() => {
      /**
       * This flag will allow teams to know when the script is loaded so they can
       * either send events or run fallback behavior
       */
      window.ELF_LOADED = true

      /**
       * Listen for click events on elements with data-trigger-bankrate-form attr.
       * Clicking on these elements will bring up the Modal.
       */
      const [fallbackAttr, triggerAttr, formUrlAttr] = expectedAttrs
      document.querySelectorAll(`[${triggerAttr}]`).forEach(el => {
        el.addEventListener('click', e => {
          const { attributes } = e.target

          if (!hasValidAttributes(attributes)) {
            throw new Error(
              `Attributes on clicked element are invalid. Verify that 'data-bankrate-form-trigger' and 'data-bankrate-form-url' are added to the DOM element.`
            )
          }

          triggerHandle(
            attributes[formUrlAttr].value,
            attributes[triggerAttr].value,
            attributes[fallbackAttr] && attributes[fallbackAttr].value
          )
        })
      })

      /**
       * Listen for messages emitted inside the iframe
       */
      window.addEventListener('message', ({ data }) => {
        switch (data.name) {
          /** @public */
          case 'REDIRECT_USER':
            // TO DO: send to new relic
            // datadog.increment('elf_redirected')

            /**
             * event payload signature:
             * { name, redirectUrl }
             */
            if (data.tab) {
              return window.open(data.redirectUrl, '_blank')
            }

            window.location.replace(data.redirectUrl)
            break

          case 'HIDE_EMBEDDED_FORM':
            // TO DO: send to new relic
            // datadog.increment('elf_hidden')

            /**
             * event payload signature:
             * { name }
             */
            showModal.value = false
            outcome.value = null
            break

          case 'SHOW_EMBEDDED_FORM':
            /**
             * event payload signature:
             * { name, formUrl, triggerId, fallbackUrl, context }
             */
            triggerHandle(data.formUrl, data.triggerId, data.fallbackUrl)

            if (data.context) {
              contextData.value = data.context
            }

            break

          case 'SHOW_EMBEDDED_WIDGET':
            /**
             * event payload signature:
             * { name, category, context }
             */
            triggerHandle(getWidgetUrl(data.category, data.context))

            break

          /** @private */
          case 'COVERAGE_GATE_OUTCOME':
            /**
             * event payload signature:
             * { name, success }
             */
            outcome.value = { success: data.success }

            // TO DO: send to new relic
            // datadog.increment('elf_coverage_gate_outcome', outcome.value)

            if (!data.success && fallbackUrl.value) {
              window.location.replace(fallbackUrl.value)
            }
            break

          case 'APP_READY':
            outcome.value = { success: data.success }

            // TO DO: send to new relic
            // datadog.increment('app_ready', outcome.value)
            break

          case 'SHOW_EMBEDDED_IN_PAGE_FORM':
            /**
             * event payload signature:
             * { name, formUrl, triggerId, context }
             */
            triggerHandle(
              getInPageIFrameUrl(data.formUrl, data.context),
              data.triggerId,
              undefined,
              true
            )

            if (data.context) {
              contextData.value = data.context
            }

            break
        }
      })
    })

    return {
      formUrl,
      outcome,
      showModal,
      clickSource,
      fallbackUrl,
      startTime
    }
  }
}
</script>
