<template>
  <div role="status" class="elf__circular-loader" data-testid="circular-loader">
    <span class="elf__visually-hidden">Loading</span>
    <div class="elf__circular-loader__content">
      <svg viewBox="25 25 50 50" class="elf__circular-loader__spinner">
        <circle
          cx="50"
          cy="50"
          r="20"
          fill="none"
          stroke-miterlimit="10"
          class="elf__circular-loader__spinner-track"
        ></circle>
        <circle
          cx="50"
          cy="50"
          r="20"
          fill="none"
          stroke-miterlimit="10"
          class="elf__circular-loader__spinner-dash"
        ></circle>
      </svg>
      <div class="elf__circular-loader__icon-container">
        <span class="elf__circular-loader__icon icon"
          ><svg
            focusable="false"
            viewBox="0 0 24 24"
            class="elf__icon__glyph"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Home</title>
            <path
              d="M24 8.51L12 .85l-3.13 2V0H2.61v6.83L0 8.51l1.12 1.76 1.53-1V24h18.74v-4.17H19.3v2.08h-3.13V11.48H7.83v10.43H4.7V8L12 3.32 19.3 8v8.7h2.09V9.3l1.53 1zM9.91 13.57h4.18v8.34H9.91zM6.78 2.09v2.08L4.7 5.5V2.09z"
            ></path></svg
        ></span>
      </div>
    </div>
  </div>
</template>

<script>
export default { name: 'Loader' }
</script>

<style lang="scss">
.elf__circular-loader {
  height: 7.11111rem;
  width: 7.11111rem;
  position: relative;
  display: inline-block;

  .elf__visually-hidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  &__content {
    margin: 0 auto;
    position: relative;
    z-index: 0;

    &::before {
      content: '';
      display: block;
      padding-top: 100%;
    }
  }

  &__spinner {
    -webkit-animation: rotate 2s linear infinite;
    animation: rotate 2s linear infinite;
    height: 100%;
    transform-origin: center center;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    overflow: hidden;
  }

  &__spinner-track {
    stroke: #e6eeff;
    stroke-width: 3;
  }

  &__spinner-dash {
    animation: dash 1.5s ease-in-out infinite;
    stroke: #0157ff;
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    stroke-linecap: round;
    stroke-width: 3;
    z-index: 0;
  }

  &__icon-container {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 50%;
    transform: translate(-50%, -50%);
    z-index: 0;
    text-align: center;
  }

  &__icon {
    color: inherit;
    width: 50%;
    height: 50%;
    display: block;
    margin: auto;
    position: relative;
    color: inherit;
  }

  .elf__icon__glyph {
    overflow: hidden;
    display: block;
    fill: currentColor;
    height: 100%;
    user-select: none;
    width: 100%;
  }
}

@keyframes rotate {
  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }

  to {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}
</style>
