<template>
  <div class="elf__empty-area" data-testid="fallback">
    <h2>No lenders found matching this criteria</h2>
    <p>
      It looks like we couldn't find any lenders that matched the provided
      criteria. Please try again at a later time.
    </p>
    <button class="elf__button" @click="closeModal">Close</button>
  </div>
</template>

<script>
export default {
  name: 'Fallback',
  methods: {
    closeModal() {
      window.postMessage({ name: 'HIDE_EMBEDDED_FORM' }, '*')
    }
  }
}
</script>

<style lang="scss" scoped>
.elf__empty-area {
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;

  .elf__button {
    line-height: 1.55556;
    border-radius: 0.22222rem;
    letter-spacing: 0.02778rem;
    padding: 0.77778rem 1.33333rem;
    font-size: 1rem;
    background-color: #fff;
    border: 2px solid #0157ff;
    color: #0157ff;
    font-weight: 600;
    transition: background-color 0.25s cubic-bezier(0.17, 0.67, 0.44, 0.89),
      border-color 0.25s cubic-bezier(0.17, 0.67, 0.44, 0.89),
      color 0.25s cubic-bezier(0.17, 0.67, 0.44, 0.89);
    max-width: 21.33333rem;
    -webkit-appearance: none;
    cursor: pointer;
    display: inline-block;
    font-style: normal;
    margin: 0;
    overflow: visible;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    white-space: normal;

    &:hover {
      background-color: #f2f7ff;
    }
  }
}
</style>
