<template>
  <div
    tabindex="-1"
    data-testid="dialog-modal"
    class="elf__dialog elf__dialog-modal elf__dialog-center"
  >
    <div
      class="elf__dialog-overlay elf__animated elf__fadeInHalf"
      @click="closeModal"
    ></div>
    <div class="elf__dialog-panel elf__animated elf__fadeInDown">
      <button class="elf__close-dialog" @click="closeModal">
        <svg
          width="17"
          height="17"
          style="position: absolute"
          xmlns="http://www.w3.org/2000/svg"
        >
          <title>Close icon</title>
          <path
            d="M15.577.808l1.23 1.23-6.77 6.77 6.77 6.769-1.23 1.23-6.77-6.77-6.769 6.77-1.23-1.23 6.769-6.77-6.77-6.769L2.039.808l6.77 6.77 6.769-6.77z"
            fill="#151515"
            fill-rule="evenodd"
          />
        </svg>
      </button>
      <div class="elf__dialog-body">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Modal',
  methods: {
    closeModal() {
      window.postMessage({ name: 'HIDE_EMBEDDED_FORM' }, '*')
    }
  }
}
</script>

<style lang="scss" scoped>
.elf__dialog {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99999;
  overflow-x: hidden;
  overflow-y: auto;
  min-width: 100%;
  max-height: 100vh;
  -webkit-overflow-scrolling: initial;
  display: flex;
  align-items: center;

  .elf__dialog-panel {
    z-index: 3800;
    position: relative;
    padding: 0;
    transition: margin-top 0.25s ease-in-out;
    border: 3px solid rgba(255, 255, 255, 0.2);
    border-radius: 8px;
    height: 100%;
    width: 100%;
    display: flex;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;

    /**
    Fixes an issue inside Bankrate.com where we customize the scrollbar and
    it causes the modal to shift to the left.
  */
    &::-webkit-scrollbar {
      all: inherit;
    }

    button.elf__close-dialog {
      background-color: transparent;
      box-shadow: 0 0 0 4px transparent;
      border-radius: 0;
      cursor: pointer;
      color: inherit;
      position: absolute;
      top: 20px;
      z-index: 1000;
      right: 20px;
      border: none;
      appearance: none;
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .elf__dialog-body {
      flex-grow: 1;
      align-items: center;
      justify-content: center;
      display: flex;
      position: relative;
      z-index: 1;
      background-color: #f5f6f8;
      border-radius: 6px;
      padding: 0;
    }
  }

  .elf__dialog-overlay {
    display: block;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    background-color: #4a4a4a;
    z-index: 3500;
  }
}

@media (min-width: 640px) {
  .elf__dialog .elf__dialog-panel {
    margin-left: auto;
    margin-right: auto;
    max-width: 92%;
    height: 92vh;
    overflow: auto;
  }
}
</style>
