/**
 * If there is a mounting node, we want to use that as the root node for the app.
 * Otherwise, we want to create a new node and append it to the body.
 * @returns {HTMLElement}
 */

export const getMountingNode = () => {
  const mountingNode = document.getElementById('elf-mounting-node')

  if (mountingNode) {
    return mountingNode
  }

  const el = document.createElement('div')
  document.body.appendChild(el)
  return el
}

export const toggleBodyClass = () => {
  document.body.classList.toggle('elf__modal-open')

  // Set initial height when modal is opened
  setPageHeightVar()

  // Listen to resize event in case users turn their phones and the page height changes
  window.addEventListener('resize', setPageHeightVar)
}

const setPageHeightVar = () => {
  // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
  const vh = window.innerHeight * 0.01
  // Then set the value in the custom property to the root of the document
  document.documentElement.style.setProperty('--elf-page-vh', `${vh}px`)
}
