import Rollbar from 'rollbar'

const {
  BASE_URL,
  VUE_APP_ENV,
  VUE_APP_COMMIT_SHA,
  VUE_APP_ROLLBAR_TOKEN
} = process.env

const rollbarOptions = {
  accessToken: VUE_APP_ROLLBAR_TOKEN,
  captureUncaught: true,
  scrubTelemetryInputs: true,
  captureUnhandledRejections: true,
  verbose: true,
  hostSafeList: [BASE_URL],
  payload: {
    environment: VUE_APP_ENV,
    client: {
      javascript: {
        code_version: VUE_APP_COMMIT_SHA,
        source_map_enabled: true
      }
    }
  }
}

export default Rollbar.init(rollbarOptions)
