export const updateQueryParams = (url, params) => {
  const [baseUrl, search] = url.split('?')

  const uri = search ? '?' + search : ''

  const updatedSearchQuery = Object.entries(params).reduce(
    (updatedUri, [key, value]) => {
      const param = `${key}=${value}`
      const matcher = new RegExp(`([?&])${key}=.*?(&|$)`, 'i')
      const separator = updatedUri.indexOf('?') !== -1 ? '&' : '?'

      return updatedUri.match(matcher)
        ? updatedUri.replace(matcher, `$1${param}$2`)
        : updatedUri + separator + param
    },
    uri
  )

  return baseUrl + updatedSearchQuery
}

export const getParentUtmParams = () => {
  const utmParamsList = [
    'brid',
    'gclid',
    'utm_adid',
    'utm_term',
    'utm_adgid',
    'utm_cmpid',
    'utm_source',
    'utm_medium',
    'utm_content',
    'utm_campaign',
    'utm_keywordid'
  ]

  // Capture query string
  const query = window.location.search

  // If no query string, return empty object
  if (query === '') {
    return {}
  }

  return (
    query
      // Remove the '?' in front of query string
      .slice(1)
      // Split the query string into key=value pairs
      .split('&')
      // Loop through each key=value pair
      .reduce((result, part) => {
        const [key, value] = part.split('=')

        if (!utmParamsList.includes(key)) {
          return result
        }

        // If the key doesn't exist yet, set it
        if (!result[key]) {
          result[key] = value
        } else {
          // If it's not an array, make it an array
          if (!Array.isArray(result[key])) {
            result[key] = [result[key]]
          }

          // Push the new value to the key's array
          result[key].push(value)
        }

        return result
      }, {})
  )
}
