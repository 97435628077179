export function emitContextData(data) {
  if (!data) return

  const parentApp = document.getElementById('elf-iframe')

  if (!parentApp) {
    return
  }

  parentApp.contentWindow.postMessage(
    { name: 'SC_SEND_CONTEXT', context: sanitizeData(data) },
    '*'
  )
}

/**
 * Removes any data that is not an allowed field. Converts values to String
 *
 * @param data
 * @returns {{}}
 */
export function sanitizeData(data) {
  const allowedKeys = [
    'zipcode',
    'zipCode',
    'productType',
    'propertyUse',
    'propertyType',
    'purchasePrice',
    'propertyPurchasePrice',
    'cashOutAmount',
    'firstMortgageRate',
    'creditScore',
    'firstTimeBuyer',
    'downPaymentPercentage',
    'propertyValue',
    'currentLoanBalance',
    'firstName',
    'lastName',
    'phone',
    'email',
    'propertyCity',
    'city',
    'propertyState',
    'state',
    'lookingFor',
    'personalOrBusiness',
    'companyName',
    'agentUuid',
    'userUuid',
    'borrowerUuid',
    'dti',
    'fullAddress'
  ]

  return allowedKeys.reduce(
    (acc, key) => (key in data && (acc[key] = String(data[key])), acc),
    {}
  )
}
