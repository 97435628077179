<template>
  <iframe
    id="elf-iframe"
    width="100%"
    height="100%"
    :src="iframeUrl"
    data-testid="iframe"
    class="elf__animated elf__fadeIn"
    referrerpolicy="strict-origin-when-cross-origin cross-origin"
    sandbox="allow-scripts allow-same-origin allow-top-navigation allow-forms allow-popups"
    aria-label="embedded form"
  ></iframe>
</template>

<script>
import { updateQueryParams, getParentUtmParams } from '@/utils/url'

export default {
  name: 'Iframe',

  props: {
    formUrl: String,
    clickSource: String
  },

  computed: {
    iframeUrl() {
      return updateQueryParams(this.formUrl, {
        embedded: true,
        ...(this.clickSource ? { utmcta: this.clickSource } : {}),
        ...getParentUtmParams()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
iframe {
  border-radius: 6px;
  border: 0;
}
</style>
