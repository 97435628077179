<template>
  <Loader v-if="!outcome" />
  <Iframe
    :formUrl="formUrl"
    :clickSource="clickSource"
    v-show="outcome && outcome.success"
  />
  <Fallback v-show="outcome && !outcome.success && !fallbackUrl" />
</template>

<script>
import Loader from '@/components/Loader'
import Iframe from '@/components/Iframe'
import Fallback from '@/components/Fallback'

export default {
  name: 'IFrameLoader',

  props: {
    formUrl: String,
    outcome: Object,
    clickSource: String,
    fallbackUrl: String
  },

  components: {
    Loader,
    Iframe,
    Fallback
  }
}
</script>
